import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = ({ to, size, children }) => {
  const Name = styled(Link)`
    color: #000;
    box-shadow: none;
    font-family: system-ui;
    font-size: ${size ? size : "18px"};

    &:hover {
      text-decoration: underline;
    }
  `
  return <Name to={to}>{children}</Name>
}

export default StyledLink
