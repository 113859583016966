import React from "react"
import Image from "gatsby-image"
import StyledLink from "./styled-link"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      github: file(absolutePath: { regex: "/github-logo/" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      twitter: file(absolutePath: { regex: "/twitter-logo/" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rss: file(absolutePath: { regex: "/rss/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const header = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h3
            style={{
              fontFamily: `system-ui`,
              marginTop: 0,
              fontWeight: 500,
            }}
          >
            <StyledLink size="24px" to={`/`}>
              Scott Gangemi
            </StyledLink>
          </h3>
        </div>
        <div>
          <StyledLink to="/about">About</StyledLink>
        </div>
      </div>
      <hr />
    </>
  )

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>© {new Date().getFullYear()} Scott Gangemi</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "100px",
              justifyContent: "space-between",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                boxShadow: "none",
              }}
              href="https://www.github.com/gangezilla"
            >
              <Image
                fixed={data.github.childImageSharp.fixed}
                alt="Github logo"
                style={{
                  height: "32px",
                  width: "32px",
                }}
              />
            </a>
            <a
              style={{
                textDecoration: "none",
                boxShadow: "none",
                filter: "grayscale(100%)",
              }}
              href="https://www.twitter.com/gangezilla"
            >
              <Image
                fixed={data.twitter.childImageSharp.fixed}
                alt="Twitter logo"
                style={{ height: "52px", width: "52px" }}
              />
            </a>
            {/* RSS */}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
